import { Row, Col } from "react-bootstrap";
import servicesimg1 from "../images/services-img/image1.png";
import servicesimg2 from "../images/services-img/img2.jpg";
import servicesimg3 from "../images/services-img/img3.jpg";
import servicesimg4 from "../images/services-img/image4.png";
import { useEffect } from "react";

const Services = ({ t, i18n }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `${t("nav.services")} - Genimaje Tax Multi Services`;
  }, [i18n.language, t]);

  return (
    <main className="services">
      <h1 className="text-center">{t("servicesPage.headline")}</h1>
      <Row data-aos="fade-right" className="row1">
        <Col className="text-content" lg="6" md="6" sm="12" xs="12">
          <h2>{t("servicesPage.incomeTax")}</h2>
          <p>{t("servicesPage.incomeTaxText")}</p>
          <ul>
            <li>{t("servicesPage.incomeTaxLi1")}</li>
            <li>{t("servicesPage.incomeTaxLi2")}</li>
            <li>{t("servicesPage.incomeTaxLi3")}</li>
          </ul>
        </Col>
        <Col className="col" lg="6" md="6" sm="12" xs="12">
          <img src={servicesimg1} alt={t("servicesPage.incomeTax")} />
        </Col>
      </Row>
      <Row data-aos="fade-left" className="row2">
        <Col className="col" lg="6" md="6" sm="12" xs="12">
          <img src={servicesimg2} alt={t("servicesPage.autoInsurance")} />
        </Col>
        <Col className="text-content" lg="6" md="6" sm="12" xs="12">
          <h2>{t("servicesPage.autoInsurance")}</h2>
          <p>{t("servicesPage.autoInsuranceText")}</p>
          <ul>
            <li>{t("servicesPage.autoInsuranceLi1")}</li>
            <li>{t("servicesPage.autoInsuranceLi2")}</li>
            <li>{t("servicesPage.autoInsuranceLi3")}</li>
            <li>{t("servicesPage.autoInsuranceLi4")}</li>
          </ul>
        </Col>
      </Row>
      <Row data-aos="fade-right" className="row3">
        <Col className="text-content" lg="6" md="6" sm="12" xs="12">
          <h2>{t("servicesPage.notary")}</h2>
          <p>{t("servicesPage.notaryText")}</p>
          <ul>
            <li>{t("servicesPage.notaryLi1")}</li>
            <li>{t("servicesPage.notaryLi2")}</li>
            <li>{t("servicesPage.notaryLi3")}</li>
            <li>{t("servicesPage.notaryLi4")}</li>
            <li>{t("servicesPage.notaryLi5")}</li>
            <li>{t("servicesPage.notaryLi6")}</li>
            <li>{t("servicesPage.notaryLi7")}</li>
            <li>{t("servicesPage.notaryLi8")}</li>
            <li>{t("servicesPage.notaryLi9")}</li>
          </ul>
        </Col>
        <Col className="col" lg="6" md="6" sm="12" xs="12">
          <img src={servicesimg3} alt={t("servicesPage.notary")} />
        </Col>
      </Row>
      <Row data-aos="fade-left">
        <Col className="col" lg="6" md="6" sm="12" xs="12">
          <img src={servicesimg4} alt={t("servicesPage.otherServices")} />
        </Col>
        <Col className="text-content" lg="6" md="6" sm="12" xs="12">
          <h2>{t("servicesPage.otherServices")}</h2>
          <ul>
            <li>{t("servicesPage.otherServicesLi1")}</li>
            <li>{t("servicesPage.otherServicesLi2")}</li>
            <li>{t("servicesPage.otherServicesLi3")}</li>
            <li>{t("servicesPage.otherServicesLi4")}</li>
          </ul>
        </Col>
      </Row>
    </main>
  );
};

export default Services;
