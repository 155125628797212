import { useState, useEffect } from "react";

import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import usflag from "../../images/us-flag.png";
import mexicoflag from "../../images/mexico-flag.png";

const Navlinks = ({ t, i18n }) => {
  const [navState, changeNavState] = useState({
    activeLink: null,
    linksData: [
      { id: 1, name: t("nav.home"), to: "/" },
      { id: 2, name: t("nav.about"), to: "/about" },
      { id: 3, name: t("nav.services"), to: "/services" },
      { id: 4, name: t("nav.faqs"), to: "/faqs" },
      { id: 4, name: t("nav.contact"), to: "/contact" },
    ],
  });

  useEffect(() => {
    changeNavState({
      activeLink: null,
      linksData: [
        { id: 1, name: t("nav.home"), to: "/" },
        { id: 2, name: t("nav.about"), to: "/about" },
        { id: 3, name: t("nav.services"), to: "/services" },
        { id: 4, name: t("nav.faqs"), to: "/faqs" },
        { id: 4, name: t("nav.contact"), to: "/contact" },
      ],
    });
  }, [i18n.language, t]);
  const toggleLink = (index) => {
    changeNavState({ ...navState, activeLink: navState.linksData[index] });
  };
  const toggleLinkStyle = (index) =>
    navState.linksData[index] === navState.activeLink ? "active" : "inactive";

  const handleClick = () => {
    i18n.changeLanguage(i18n.language === "en" ? "es" : "en");
  };
  return (
    <>
      {navState.linksData.map((link, index) => {
        return (
          <Nav.Link
            as="li"
            key={index}
            onClick={() => toggleLink(index)}
            className={toggleLinkStyle(index)}
          >
            <Link to={link.to}>{link.name}</Link>
          </Nav.Link>
        );
      })}
      <Nav.Link onClick={() => handleClick()} as="li">
        {i18n.language === "en" ? (
          <span>
            {" "}
            <img className="flag" src={mexicoflag} alt="Mexico flag" /> Español
          </span>
        ) : (
          <span>
            {" "}
            <img className="flag" src={usflag} alt="Uk flag" /> English
          </span>
        )}
      </Nav.Link>
    </>
  );
};

export default Navlinks;
