import { useState, useRef } from "react";

import Navlinks from "./Navlinks";

import Sidebar from "./Sidebar";

import logo from "../../images/logo.png";
import { Link } from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const Navigation = ({ t, i18n }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navRef = useRef();

  const handleScroll = () => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 20) {
        navRef.current && navRef.current.classList.add("scrolled");
      } else {
        navRef.current && navRef.current.classList.remove("scrolled");
      }
    });
  };
  handleScroll();
  return (
    <>
      <div ref={navRef} className="nav-container d-flex align-items-center ">
        <Navbar className="justify-content-between">
          <Navbar.Brand>
            <Link to="/">
              <img className="logo" src={logo} alt="logo" />
            </Link>
          </Navbar.Brand>

          <div className="mobile-menu-container">
            <div
              onClick={() => setIsSidebarOpen((prevState) => true)}
              className="bars-icon-container"
            >
              <FontAwesomeIcon className="bars-icon" icon={faBars} />
            </div>
            <Sidebar
              t={t}
              i18n={i18n}
              isSidebarOpen={isSidebarOpen}
              setIsSidebarOpen={setIsSidebarOpen}
            />
          </div>
          <Nav>
            <Navlinks t={t} i18n={i18n} />
          </Nav>
        </Navbar>
      </div>
    </>
  );
};

export default Navigation;
