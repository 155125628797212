import cover from "../images/faqs-imgs/cover.png";
import { Accordion } from "react-bootstrap";
import { useEffect } from "react";
const Faqs = ({ t, i18n }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `${t("nav.faqs")} - Genimaje Tax Multi Services`;
  }, [i18n.language, t]);
  return (
    <main className="faqs">
      <h1 className="text-center">FAQs</h1>
      <div className="d-flex flex-wrap align-items-center">
        <div className="col faqs-container">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>{t("faqsPage.personalInfo")}</Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>{t("faqsPage.personalInfoLi1")}</li>
                  <li>{t("faqsPage.personalInfoLi2")}</li>
                  <li>{t("faqsPage.personalInfoLi3")}</li>
                  <li>{t("faqsPage.personalInfoLi4")}</li>
                  <li>{t("faqsPage.personalInfoLi5")}</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                {t("faqsPage.infoAboutIncome")}
              </Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>{t("faqsPage.infoAboutIncomeLi1")}</li>
                  <li>{t("faqsPage.infoAboutIncomeLi2")}</li>
                  <li>{t("faqsPage.infoAboutIncomeLi3")}</li>
                  <li>{t("faqsPage.infoAboutIncomeLi4")}</li>
                  <li>{t("faqsPage.infoAboutIncomeLi5")}</li>
                  <li>{t("faqsPage.infoAboutIncomeLi6")}</li>
                  <li>{t("faqsPage.infoAboutIncomeLi7")}</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>{t("faqsPage.financialInfo")}</Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>{t("faqsPage.financialInfoLi1")}</li>
                  <li>{t("faqsPage.financialInfoLi2")}</li>
                  <li>{t("faqsPage.financialInfoLi3")}</li>
                </ul>
                <p className="mt-5">{t("faqsPage.personalExpenses")}</p>
                <ul>
                  <li>{t("faqsPage.personalExpensesLi1")}</li>
                  <li>{t("faqsPage.personalExpensesLi2")}</li>
                  <li>{t("faqsPage.personalExpensesLi3")}</li>
                  <li>{t("faqsPage.personalExpensesLi4")}</li>
                  <li>{t("faqsPage.personalExpensesLi5")}</li>
                  <li>{t("faqsPage.personalExpensesLi6")}</li>
                  <li>{t("faqsPage.personalExpensesLi7")}</li>
                  <li>{t("faqsPage.personalExpensesLi8")}</li>
                </ul>
                <p className="mt-5">
                  <strong>{t("faqsPage.socialSecurity")}</strong>
                </p>
                <ul>
                  <li>{t("faqsPage.socialSecurityLi1")}</li>
                  <li>{t("faqsPage.socialSecurityLi2")}</li>
                  <li>{t("faqsPage.socialSecurityLi3")}</li>
                  <li>{t("faqsPage.socialSecurityLi4")}</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                {t("faqsPage.otherDeductions")}
              </Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>{t("faqsPage.otherDeductionsLi1")}</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
      <div data-aos="fade-up" className="cover-container">
        <img src={cover} alt="Cover" />
      </div>
    </main>
  );
};

export default Faqs;
