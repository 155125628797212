import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Alert, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import {
  faMapMarkerAlt,
  faPhoneAlt,
  faFax,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
const emailRegix =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneRegix = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;

const Contact = ({ t, i18n }) => {
  const [isSubmittedSuccess, setisSubmittedSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    axios
      .post("https://formspree.io/f/xeqvdlar", data)
      .then((res) => {
        setisSubmittedSuccess(true);
      })
      .catch((error) => alert(JSON.stringify(error)));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `${t("nav.contact")} - Genimaje Tax Multi Services`;
  }, [i18n.language, t]);
  return (
    <>
      <main className="contact">
        <h1 className="text-center">{t("contactPage.headline")}</h1>
        <div className="d-flex justify-content-center flex-wrap flex-container">
          <div className="flex-item1">
            <h3>{t("contactPage.howToReach")}</h3>
            <div className="d-flex align-items-center fb-container">
              <FontAwesomeIcon className="icon" icon={faFacebookSquare} />
              <p>
                {t("contactPage.followUs")}{" "}
                <a
                  href="https://www.facebook.com/Genimajetaxmultiservices"
                  target="_blank"
                  rel="noreferrer"
                  className="strong"
                >
                  Facebook
                </a>
              </p>
            </div>
            <div className="d-flex align-items-center address-container">
              <FontAwesomeIcon className="icon" icon={faMapMarkerAlt} />
              <p>
                <span>{t("contactPage.adress")} </span>
                {t("contactPage.adressInfo")}
              </p>
            </div>
            <div className="d-flex align-items-center phone-container">
              <FontAwesomeIcon className="icon" icon={faPhoneAlt} />
              <p>
                <span>{t("contactPage.phone")} </span>
                {t("contactPage.phoneNum")}
              </p>
            </div>
            <div className="d-flex align-items-center fax-container">
              <FontAwesomeIcon className="icon" icon={faFax} />
              <p>
                <span>{t("contactPage.fax")} </span>
                {t("contactPage.faxNum")}
              </p>
            </div>
            <div className="d-flex align-items-center email-container">
              <FontAwesomeIcon className="icon" icon={faEnvelope} />
              <p>
                <span>{t("contactPage.email")} </span>
                {t("contactPage.emailAddress")}
              </p>
            </div>
          </div>

          <div className="flex-item2">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-4">
                <Form.Label>
                  {t("contactPage.name")} <span className="star">*</span>
                </Form.Label>
                <div className="flex-input-container d-flex">
                  <div className="input-container">
                    <Form.Control
                      {...register("firstName", {
                        required: {
                          value: true,
                          message: "Please provide your first name",
                        },
                      })}
                      type="text"
                    />
                    <Form.Text>{t("contactPage.first")}</Form.Text>
                    <ErrorMessage
                      style={{ color: "red" }}
                      as="p"
                      errors={errors}
                      name="firstName"
                    />
                  </div>
                  <div className="input-container">
                    <Form.Control
                      {...register("lastName", {
                        required: {
                          value: true,
                          message: "Please provide your last name",
                        },
                      })}
                      type="text"
                    />
                    <Form.Text>{t("contactPage.last")}</Form.Text>
                    <ErrorMessage
                      style={{ color: "red" }}
                      as="p"
                      errors={errors}
                      name="lastName"
                    />
                  </div>
                </div>
              </Form.Group>
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <Form.Label>
                  {t("contactPage.emailForm")}
                  <span className="star">*</span>
                </Form.Label>
                <Form.Control
                  {...register("email", {
                    required: {
                      value: true,
                      message: "Please provide your email",
                    },
                    pattern: { value: emailRegix, message: "Invalid Email" },
                  })}
                  type="text"
                />
                <ErrorMessage
                  style={{ color: "red" }}
                  as="p"
                  errors={errors}
                  name="email"
                />
              </Form.Group>

              <Form.Group className="mb-4">
                <Form.Label>
                  {t("contactPage.phoneForm")}
                  <span className="star">*</span>
                </Form.Label>
                <Form.Control
                  {...register("tel", {
                    required: {
                      value: true,
                      message: "Please provide phone number",
                    },
                    pattern: {
                      value: phoneRegix,
                      message: "Invalid phone number",
                    },
                  })}
                  type="tel"
                />
                <ErrorMessage
                  style={{ color: "red" }}
                  as="p"
                  errors={errors}
                  name="tel"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="select-affair">
                  {t("contactPage.affair")}
                </Form.Label>
                <Form.Select
                  {...register("affair", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  })}
                  className="mb-4"
                  id="select-affair"
                  aria-label="Default select example"
                >
                  <option value="Taxes">{t("contactPage.affairItem1")}</option>
                  <option value="Insurance">
                    {t("contactPage.affairItem2")}
                  </option>
                  <option value="Notary">{t("contactPage.affairItem3")}</option>
                </Form.Select>
                <ErrorMessage
                  style={{ color: "red" }}
                  as="p"
                  errors={errors}
                  name="affair"
                />
              </Form.Group>
              <Form.Group className="mb-4" controlId="textarea">
                <Form.Control {...register("message")} as="textarea" rows={3} />
              </Form.Group>
              <Button type="submit">Submit</Button>
              {isSubmittedSuccess && (
                <Alert className="mt-3" variant="success">
                  Your message is submitted successfully!
                </Alert>
              )}
            </Form>
          </div>
        </div>
      </main>
      <section className="map-container">
        <iframe
          title="Company Title"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d216.5877584082983!2d-95.5192107!3d29.7080507!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c2581a251fa3%3A0x7cedcae50d5adc7d!2s7607%20De%20Moss%20Dr%2C%20Houston%2C%20TX%2077036%2C%20USA!5e0!3m2!1sen!2sma!4v1631222681092!5m2!1sen!2sma"
          style={{ border: 0 }}
          allowFullScreen=""
          width="100%"
          height="400"
          loading="lazy"
        ></iframe>
      </section>
    </>
  );
};

export default Contact;
