import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Navigation from "./components/navigation/Navigation";
import Footer from "./components/Footer";
import Backtotop from "./components/Backtotop";
import Home from "./components/Home";
import About from "./components/About";
import Services from "./components/Services";
import Faqs from "./components/Faqs";
import Contact from "./components/Contact";
import "./i18n";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import "aos/dist/aos.css";
import AOS from "aos";
// main app
function App() {
  AOS.init();
  const { t } = useTranslation();
  return (
    <Router>
      <Navigation t={t} i18n={i18n} />
      <Switch>
        <Route path="/" exact>
          <Home t={t} i18n={i18n} />
        </Route>
        <Route path="/about" exact>
          <About i18n={i18n} t={t} />
        </Route>
        <Route path="/contact" exact>
          <Contact i18n={i18n} t={t} />
        </Route>
        <Route path="/services" exact>
          <Services i18n={i18n} t={t} />
        </Route>
        <Route path="/faqs" exact>
          <Faqs i18n={i18n} t={t} />
        </Route>
      </Switch>
      <Backtotop />
      <Footer />
    </Router>
  );
}

export default App;
