import { useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import {
  faMapMarkerAlt,
  faPhoneAlt,
  faFax,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

import qualitiesimg1 from "../images/home-imgs/img1.png";
import qualitiesimg2 from "../images/home-imgs/img2.png";
import qualitiesimg3 from "../images/home-imgs/img3.png";
import qualitiesimg4 from "../images/home-imgs/img4.png";
import serviceIcon1 from "../images/home-imgs/img5.png";
import serviceIcon2 from "../images/home-imgs/img6.png";
import serviceIcon3 from "../images/home-imgs/img7.png";

import { Carousel } from "react-bootstrap";

const Home = ({ t, i18n }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Genimaje Tax Multi Services - Income Tax - Notary Public";
  }, [i18n.language]);

  return (
    <>
      <div className="slider-container">
        <Carousel>
          <Carousel.Item className="slider-item-el item1">
            <div className="content-container">
              <div className="text-container">
                <h1 data-aos="fade-up" data-aos-duration="1000">
                  {t("homePage.sliderSection.slideText1")}
                </h1>

                <p
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="500"
                  className="light-blue-bg"
                >
                  {t("homePage.sliderSection.slideText2")}
                </p>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item className="slider-item-el item2">
            <div className="content-container">
              <div className="text-container">
                <h1>{t("homePage.sliderSection.slideText3")}</h1>
                <p className="big-text">
                  {t("homePage.sliderSection.slideText4")}
                </p>
                <p className="light-blue-bg">
                  {t("homePage.sliderSection.slideText5")}
                </p>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item className="slider-item-el item3">
            <div className="content-container">
              <div className="text-container text-center">
                <h1>{t("homePage.sliderSection.slideText6")}</h1>
                <p className="big-text big-text-dark">
                  {t("homePage.sliderSection.slideText7")}
                </p>
                <p className="light-blue-bg">
                  {t("homePage.sliderSection.slideText8")}
                </p>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item className="slider-item-el item4">
            <div className="content-container">
              <div className="text-container text-center">
                <h1>{t("homePage.sliderSection.slideText9")}</h1>
                <p className="big-text">
                  {t("homePage.sliderSection.slideText10")}
                </p>
                <p className="light-blue-bg">
                  {t("homePage.sliderSection.slideText11")}
                </p>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
      <section className="about-section text-center">
        <h2 className="h2">{t("homePage.aboutSection.headline")}</h2>
        <div
          data-aos="fade-right"
          className="d-flex justify-content-center flex-wrap"
        >
          <div className="flex-item text-center">
            <img src={qualitiesimg1} alt="Customer Orientation" />
            <h3>{t("homePage.aboutSection.customer")}</h3>
            <p>{t("homePage.aboutSection.customerText")}</p>
          </div>
          <div className="flex-item text-center">
            <img
              src={qualitiesimg2}
              alt={t("homePage.aboutSection.satisfaction")}
            />
            <h3>{t("homePage.aboutSection.satisfaction")}</h3>
            <p>{t("homePage.aboutSection.satisfactionText")}</p>
          </div>
          <div className="flex-item text-center">
            <img
              src={qualitiesimg3}
              alt={t("homePage.aboutSection.certified")}
            />
            <h3>{t("homePage.aboutSection.certified")}</h3>
            <p>{t("homePage.aboutSection.certifiedText")}</p>
          </div>
          <div className="flex-item text-center">
            <img
              src={qualitiesimg4}
              alt={t("homePage.aboutSection.availability")}
            />
            <h3>{t("homePage.aboutSection.availability")}</h3>
            <p>{t("homePage.aboutSection.availabilityText")}</p>
          </div>
        </div>
        <button data-aos="fade-up">
          <Link to="/about">{t("homePage.aboutSection.more")}</Link>
        </button>
      </section>
      <section className="services-section text-center">
        <div className="container">
          <h2 className="h2">{t("homePage.servicesSection.headline")}</h2>
          <p>{t("homePage.servicesSection.paragraph1")}</p>
          <div
            data-aos="fade-right"
            className="d-flex justify-content-center flex-wrap"
          >
            <div className="flex-item">
              <img
                src={serviceIcon1}
                alt={t("homePage.servicesSection.incomeTax")}
              />
              <h3>{t("homePage.servicesSection.incomeTax")}</h3>
            </div>
            <div className="flex-item">
              <img
                src={serviceIcon2}
                alt={t("homePage.servicesSection.insurance")}
              />
              <h3>{t("homePage.servicesSection.insurance")}</h3>
            </div>
            <div className="flex-item">
              <img
                src={serviceIcon3}
                alt={t("homePage.servicesSection.notary")}
              />
              <h3>{t("homePage.servicesSection.notary")}</h3>
            </div>
          </div>
          <p>{t("homePage.servicesSection.paragraph2")}</p>
          <button data-aos="fade-up">
            <Link to="/services">{t("homePage.servicesSection.more")}</Link>
          </button>
        </div>
      </section>
      <section className="faqs-section text-center">
        <div className="container">
          <h2 className="h2">{t("homePage.faqsSection.headline")}</h2>
          <p className="big-text">{t("homePage.faqsSection.paragraph1")}</p>
          <button data-aos="fade-up">
            <Link to="/faqs">{t("homePage.faqsSection.more")}</Link>
          </button>
        </div>
      </section>
      <section className="contact-section">
        <div className="d-flex justify-content-center flex-wrap flex-container">
          <div className="flex-item1 flex-grow-1 flex-lg-grow-0">
            <h3>{t("contactPage.howToReach")}</h3>
            <div className="d-flex align-items-center fb-container">
              <FontAwesomeIcon className="icon" icon={faFacebookSquare} />
              <p>
                {t("contactPage.followUs")}{" "}
                <a
                  href="https://www.facebook.com/Genimajetaxmultiservices"
                  target="_blank"
                  rel="noreferrer"
                  className="strong"
                >
                  Facebook
                </a>
              </p>
            </div>
            <div className="d-flex align-items-center address-container">
              <FontAwesomeIcon className="icon" icon={faMapMarkerAlt} />
              <p>
                <span>{t("contactPage.adress")} </span>
                {t("contactPage.adressInfo")}
              </p>
            </div>
            <div className="d-flex align-items-center phone-container">
              <FontAwesomeIcon className="icon" icon={faPhoneAlt} />
              <p>
                <span>{t("contactPage.phone")} </span>
                {t("contactPage.phoneNum")}
              </p>
            </div>
            <div className="d-flex align-items-center fax-container">
              <FontAwesomeIcon className="icon" icon={faFax} />
              <p>
                <span>{t("contactPage.fax")} </span>
                {t("contactPage.faxNum")}
              </p>
            </div>
            <div className="d-flex align-items-center email-container">
              <FontAwesomeIcon className="icon" icon={faEnvelope} />
              <p>
                <span>{t("contactPage.email")} </span>
                {t("contactPage.emailAddress")}
              </p>
            </div>
            <button>
              <Link to="/contact">Leave us a message</Link>
            </button>
          </div>

          <div className="flex-item2 d-none d-md-block">
            <iframe
              title="Company Title"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d216.5877584082983!2d-95.5192107!3d29.7080507!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c2581a251fa3%3A0x7cedcae50d5adc7d!2s7607%20De%20Moss%20Dr%2C%20Houston%2C%20TX%2077036%2C%20USA!5e0!3m2!1sen!2sma!4v1631222681092!5m2!1sen!2sma"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
