import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
const Backtotop = () => {
  return (
    <button onClick={() => window.scrollTo(0, 0)} className="back-to-top">
      <FontAwesomeIcon icon={faChevronUp} />
    </button>
  );
};

export default Backtotop;
