const Footer = () => {
  return (
    <footer>
      <div className="content">
        <p className="mb-0">
          Copyright © {new Date().getFullYear()} • GTMS - Genimaje Tax Multi
          Services • All rights reserved
        </p>
      </div>
    </footer>
  );
};

export default Footer;
