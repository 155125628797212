import { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import aboutimg1 from "../images/about-imgs/img1.png";
import aboutimg2 from "../images/about-imgs/img2.jpg";
import aboutimg3 from "../images/about-imgs/img3.jpg";
import aboutimg4 from "../images/about-imgs/img4.png";

const About = ({ t, i18n }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `${t("nav.about")} - Genimaje Tax Multi Services`;
  }, [i18n.language, t]);

  return (
    <main className="about">
      <h1 className="text-center">{t("aboutPage.headline")}</h1>
      <Row>
        <Col
          data-aos="fade-right"
          className="col"
          lg="6"
          md="6"
          sm="12"
          xs="12"
        >
          <img src={aboutimg1} alt={t("aboutPage.ourCompany")} />
        </Col>
        <Col
          data-aos="fade-left"
          className="text-content"
          lg="6"
          md="6"
          sm="12"
          xs="12"
        >
          <h2>{t("aboutPage.ourCompany")}</h2>
          <p>{t("aboutPage.ourCompanyText")}</p>
        </Col>
      </Row>
      <Row className="row2">
        <Col
          data-aos="fade-right"
          className="text-content"
          lg="6"
          md="6"
          sm="12"
          xs="12"
        >
          <h2>{t("aboutPage.ourValues")}</h2>
          <p>{t("aboutPage.ourValuesText")}</p>
        </Col>
        <Col data-aos="fade-left" className="col" lg="6" md="6" sm="12" xs="12">
          <img src={aboutimg2} alt={t("aboutPage.ourValues")} />
        </Col>
      </Row>
      <Row>
        <Col
          data-aos="fade-right"
          className="col"
          lg="6"
          md="6"
          sm="12"
          xs="12"
        >
          <img src={aboutimg3} alt={t("aboutPage.ourCommitment")} />
        </Col>
        <Col
          data-aos="fade-left"
          className="text-content"
          lg="6"
          md="6"
          sm="12"
          xs="12"
        >
          <h2>{t("aboutPage.ourCommitment")}</h2>
          <p>{t("aboutPage.ourCommitmentText")}</p>
        </Col>
      </Row>

      <div className="last-row-container">
        <h3 className="foundress-mobile">{t("aboutPage.foundress")}</h3>
        <div className="d-flex justify-content-center align-items-center flex-wrap last-row">
          <div className="foundress-info">
            <h3 className="foundress-desk">{t("aboutPage.foundress")}</h3>
            <p className="foundress-role">{t("aboutPage.foundressRole")}</p>
            <p>{t("aboutPage.foundressText")}</p>
          </div>
          <div className="img-container">
            <img src={aboutimg4} alt={t("aboutPage.foundress")} />
          </div>
        </div>
      </div>
    </main>
  );
};

export default About;
