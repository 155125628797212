import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import usflag from "../../images/us-flag.png";
import mexicoflag from "../../images/mexico-flag.png";
const Sidebar = ({ isSidebarOpen, setIsSidebarOpen, t, i18n }) => {
  const handleClick = () => {
    i18n.changeLanguage(i18n.language === "en" ? "es" : "en");
    setIsSidebarOpen(false);
  };
  return (
    <div className={`${isSidebarOpen ? "overlay open" : "overlay"}`}>
      <div className="sidebar">
        <button
          onClick={() => setIsSidebarOpen((prevState) => false)}
          className="bars-icon-container"
        >
          <FontAwesomeIcon className="bars-icon" icon={faBars} />
        </button>
        <Nav.Link as="li">
          <Link to="/">{t("nav.home")}</Link>
        </Nav.Link>
        <Nav.Link as="li">
          <Link to="/about">{t("nav.about")}</Link>
        </Nav.Link>
        <Nav.Link as="li">
          <Link to="/services">{t("nav.services")}</Link>
        </Nav.Link>
        <Nav.Link as="li">
          <Link to="/faqs">{t("nav.faqs")}</Link>
        </Nav.Link>
        <Nav.Link as="li">
          <Link to="/contact">{t("nav.contact")}</Link>
        </Nav.Link>
        <Nav.Link onClick={() => handleClick()} as="li">
          <Link to="/">
            {i18n.language === "en" ? (
              <span>
                {" "}
                <img className="flag" src={mexicoflag} alt="Mexico flag" />{" "}
                Español
              </span>
            ) : (
              <span>
                {" "}
                <img className="flag" src={usflag} alt="Us flag" /> English
              </span>
            )}
          </Link>
        </Nav.Link>
      </div>
    </div>
  );
};

export default Sidebar;
